<template>
  <ConfirmDialog :style="{width: '500px'}"/> 
  <Toast /> 
  <router-view></router-view>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
export default {
  components:{
    ConfirmDialog,
    
  },
  name: 'App',
  /**
   * Acciones de inicialización única en toda la app.
   * Aquí queremos cargar datos disponibles para toda la app
   */
  created() {
    this.$store.dispatch('datosApp/obtenerDatosGenerales');
  }
}
</script>
